
  
   .pin-circlehome {
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
    margin-bottom: -2px;
    border-radius: 50%;
    height: 33px;
    width: 33px;
    position: absolute;
    left: 10px;
    bottom: 10px;
    border-style: dotted;
    border-width: 4px;
  }

.MuiSvgIcon-root
{
  z-index: 999;
}

.MuiTypography-body1 {
    width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.MuiFormGroup-root{
  max-height: 500px!important;
  overflow: scroll!important;
  overflow-y: hidden!important; /* Hide vertical scrollbar */
  overflow-x:auto!important;
}

button.MuiButtonBase-root{
  position: absolute;
}