.homeContainer{
    display: flex;
    width: 100%;
    
}

.homesidebar{
    width: 350px;
    max-height: 100vw;

}

.homeroom{
    width:calc(100% - 300px);
    min-width: 650px;
}

.mobilesidebar{
    min-width: 100vw;
    max-height: 100vh;
   
  
  
}

.mobileroom{
    min-width: 100vw;
    max-height: 100vh;
    /*overflow: hidden;*/
}
.mobileContainer{
    display: flex;
    /*overflow: hidden;*/
    min-width: 200vw;
    max-height: 100vh;
}

.login{
  position: absolute;
  z-index: 999999;
  width: 100vw;
  height: 100vh;
  background:   #fef9e6;
}