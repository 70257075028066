.sidebar {
  /*flex: 3;*/
  /*width: 300px;*/
  top: 50px;
}
/*
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background-color: rgb(179, 179, 179);
}
*/
.sidebarWrapper {
  padding: 20px;
}

.sidebarList {
  padding: 0;
  margin: 0;
  list-style: none;
}

.sidebarListItem {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}


.css-1c2pqop.Mui-selected {
    background-color: rgba(25, 118, 210, 0.20)!important;
  }

@media only screen and (max-width: 1200px) {
  .css-1c2pqop.Mui-selected {
    background-color: rgba(25, 118, 210, 0)!important;
  }
   .MuiButtonBase-root:hover {
    background-color: rgba(25, 118, 210, 0 )!important;
  }
}

@media only screen and (max-width: 600px) {
  body {
    background-color: lightblue;
  }
}

.MuiButtonBase-root{
  padding: 20px!important;
}


.sidebarIcon {
  margin-right: 15px;
}

.sidebarButton {
  width: 150px;
  border: none;
  padding: 10px;
  border-radius: 5px;
  font-weight: 500;
}

.sidebarHr {
  margin: 20px 0;
}

.sidebarFriendList {
  padding: 0;
  margin: 0;
  list-style: none;
}

.MuiInputBase-fullWidth {
  width: auto!important;
  margin: 10px!important;
}

div .read-circle {
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: -2px;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  background-color:blue;
  position: absolute;
  right: 70px;
  bottom: 10px
}
div .replied-circle {
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: -2px;
  border-radius: 50%;
  height: 20px;
  width: 20px;
  background-color: green;
  position: absolute;
  right: 40px;
  bottom: 10px;
}

div .pin-circle {
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: -2px;
  border-radius: 50%;
  background-color: red;
  height: 20px;
  width: 20px;
  position: absolute;
  right: 10px;
  bottom: 10px;
}


div .read-circle-count {
  display: inline-block;

  border-radius: 50%;
  height: 30px;
  width: 30px;
  background-color:blue;
  position: absolute;
  left: 10px;
  bottom: 10px;
  color: white;
  text-align: center;
  line-height: 30px;
  
}
div .replied-circle-count {
  display: inline-block;

  border-radius: 50%;
  height: 30px;
  width: 30px;
  background-color: green;
  position: absolute;
  left: 50px;
  bottom: 10px;
  color: white;
  text-align: center;
  line-height:30px;
}

div .pin-circle-count {
  display: inline-block;

  border-radius: 50%;
  background-color: red;
  height: 30px;
  width: 30px;
  position: absolute;
  left: 90px;
  bottom: 10px;
 color: white;
 text-align: center;
 line-height: 30px;
}

div .connection {
  display: inline-block;

  border-radius: 50%;
  height: 30px;
  width: 30px;
  position: fixed;
  right: 10px;
  top: 10px;
 color: white;
 text-align: center;
 line-height: 30px;
 opacity: 50%;
z-index: 9999;
}
/*
div .block {
  display: inline-block;

  border-radius: 50%;
  height: 30px;
  width: 30px;
  position: fixed;
  right: 50px;
  bottom: 10px;
 background: red;
 text-align: center;
 line-height: 30px;
opacity: 0%;
z-index: 9999;
}
*/

div .block {
  display: inline-block;
  color: white;
  border-radius: 10px;
  padding: 5px;
  position: fixed;
  right: 110px;
  bottom: 90px;
 background: red;
 text-align: center;
 line-height: 30px;
opacity: 0%;
z-index: 9999;
}

div .search{
  padding-left: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 20px;
  height: 50px;
  z-index: 1;
  position: fixed;
  background: white;
  width: 320px;

}

div .mobilesearch{
  padding-left: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-right: 10px;
  height: 50px;
  z-index: 1;
  position: absolute;
  background: white;
  width: -webkit-fill-available;


}

.css-83ijpv-MuiTypography-root{
    overflow: hidden!important;
    white-space: nowrap!important;
    text-overflow: ellipsis!important;

}

