* {
    margin: 0;
    padding: 0;

  }
  
 
  
  .App {
    font-family: sans-serif;
    text-align: center;

  }
  
  .form {
    max-width: 330px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    background: #fef9e6;
    border: 2px;
    padding: 20px;
    top: 50%;
    border-color: black;
    border-style: double;
    margin-top: 25vh;
    
  }
  
  .form .form__custom-button {
    margin-top: 50px;
  }
  